/* fonts */
@font-face {
	font-family: 'AkkoPro-Regular';
	src: url('../fonts/3451FF_0_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/3451FF_0_0.woff2') format('woff2'),
		url('../fonts/3451FF_0_0.woff') format('woff'),
		url('../fonts/3451FF_0_0.ttf') format('truetype');
}  
@font-face {
	font-family: 'AkkoPro-Bold';
	src: url('../fonts/3451FF_1_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/3451FF_1_0.woff2') format('woff2'),
		url('../fonts/3451FF_1_0.woff') format('woff'),
		url('../fonts/3451FF_1_0.ttf') format('truetype');
}

.font-smoothing
{
	-webkit-font-smoothing: antialiased;
	letter-spacing: 0.03em;
}

/* 	Breakpoints
	usage example: @include xs {...};
 */

@mixin xs { //land and portai
  @media only screen and (max-width: $bp-xs) {
    @content;
  }
}

@mixin sm { //land and portai
  @media only screen and ( max-width: $bp-sm ) {
    @content;
  }
}

@mixin md {
  @media only screen and ( max-width: $bp-md ){
    @content;
  }
}

@mixin lg {
  @media only screen and (max-width: $bp-lg ) {
    @content;
  }
}
