.title-text {
	color: $grey;
	h2, h3 {
		font-family: $font-secondary;
		line-height: 1.25;
	}
	a:hover {
		color: $dark-blue;
	}
}
.title-text.tab-element-special {
	h2 {
		font-size: 1.3em;
	}
}

.title-red h2  {
	color: $red;
}
.title-red	a {
	color: $red;
}
.title-yellow h2  {
	color: $yellow;
}
.title-yellow	a {
	color: $yellow;
}
.title-green h2  {
	color: $green;
}
.title-green a {
	color: $green;
}
.title-blue h2  {
	color: $light-blue;
}
.title-blue	a {
	color: $light-blue;
}
.title-light-green h2  {
	color: $light-green;
}
.title-light-green a {
	color: $light-green;
}