
// --------------------------------------------------------------
// nice Select 
// --------------------------------------------------------------

$input_height: 36px !default;
$input_height_small: 16px !default;
$dropdown_padding:15px !default;


.nice-select {
  color:$grey;
  background-color:$light-grey;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;

  height: $input_height;
  line-height: $input_height - 2;
  outline: none;
  padding-left: $dropdown_padding;
  padding-right: $dropdown_padding + 12;
  margin-bottom: $column-gap/2;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: 48%;

  &:after { 
    border-bottom: 2px solid $grey;
    border-right: 2px solid $grey;
    content: '';
    display: block; 
    height: 12px;
    margin-top: -8px;
    pointer-events: none;
    position: absolute; 
    right: 30px; // orig. 16px 
    top: 50%; 
    transform-origin: 66% 66%;
    transform: rotate(45deg);
    transition: all 0.15s ease-in-out;
    width: 12px;
	 padding-right:10px;
  }
  
  &.open {
    //@extend :active;
    &:after {
      transform: rotate(-135deg);
    }
    .list {
      opacity: 1;
      pointer-events: auto;
      transform: scale(1) translateY(0);
      width: 100%;
    }
  }
  &.disabled {
    border-color: lighten($dark-blue, 2%);
    color: $grey;
    pointer-events: none;
    &:after { 
      border-color: lighten($dark-blue, 20%);
    }
  }
  
  // Modifiers
  &.wide {
    width: 100%;
    .list {
      left: 0 !important;
      right: 0 !important;
    }
  }
  &.right {
    float: right;
    .list {
      left: auto;
      right: 0;
    }
  }
  &.small {
  
    height: $input_height_small;
    line-height: $input_height_small - 2;
    &:after { 
      height: 4px;
      width: 4px;
    }
    .option {
      line-height: $input_height_small - 2;
      min-height: $input_height_small - 2;
    }
  }
  
  // List and options
  .list {
	 width: 100%;
    background-color: #fff;
    box-shadow: 0 0 0 1px rgba(#000000, .11);
    box-sizing: border-box;
    margin-top: 2px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%; left: 0;
    transform-origin: 50% 0;
    transform: scale(.75) translateY(- $input_height / 2);
    transition: all .2s cubic-bezier(0.5, 0, 0, 1.25), opacity .15s ease-out;
    z-index: 9;
    &:hover .option:not(:hover) {
      background-color: transparent !important;
    }
	 .option:first-child{
		 //display:none;
	}
  }
  .option {
    cursor: pointer;
    line-height: $input_height - 2;
    list-style: none;
    min-height: $input_height - 2;
    outline: none;
    padding-left: $dropdown_padding;
    padding-right: $dropdown_padding + 11;
    text-align: left;
    transition: all 0.2s;
    &:hover, &.focus, &.selected.focus { 
      background-color: $light-blue;
    }
    &.selected { 
      font-weight: $font-weight-bold;
    }
    &.disabled {
      background-color: transparent;
      color: $grey;
      cursor: default;
	    }
  }
}

// Use display instead of opacity for IE <= 10
.no-csspointerevents .nice-select {
  .list {
    display: none;
  }
  &.open {
    .list {
      display: block;
    }
  }
}

