.tx-kesearch-pi1 {
	margin: 0;
	font-family: $font;
	font-size: $font-size-base;
	color: $dark-blue;
	h2, h3, h3 a {
		color: $dark-blue;
	}
	h2 {
		font-size: $font-size-extra-large-2;
	}
	h3 {
		font-size: $font-size-large-2;
	}
	h3 a {
		font-size: $font-size-med;
	}
	.vertical-space-bottom-search {
		margin-bottom: 2rem;
	}
	.ke-search-elements {
		padding-top: 1em;
		padding-bottom: 1em;
		background: url("../../assets/images/raster/bit-raster-dark.svg") repeat-x bottom left/8px 4px;
		background-color: $light-grey;
		.ke-search-element {
			padding-left: 0;
			padding-right: 0;
			a:hover {
				color: $dark-blue;
			}
			p {
				color: $grey;
			}
			.hit {
				font-weight: $font-weight-bold;
			}
		}
	}
}