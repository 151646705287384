.main-footer {
	clear: both;
	height: 160px;
	width: 100%;
	background-color: $dark-blue;
	@include md {
		height: auto;
		padding: 1.5em 0;
	}
	.site-center {
		flex-flow: row nowrap;
		justify-content: space-between;
		padding-top: 2em;
		@include md {
			flex-flow: column wrap;
			padding-top: 1em;
		}
		@include md {
			.flex-item {
				flex: 1 0 auto !important;
			}
		}
		.flex-service {
			flex: 1.35;
		}
		.flex-address {
			flex: 1.35;
		}
		.flex-contact {
			flex: 1;
		}
		//--- Logos ---
		.flex-service .logo {
			display:block;
			margin-bottom:16px;
			img {
				width: 76px;
				height: 22px;
			}
			@include md {display:none;}
		}
		.flex-logo {
			display:none;
			@include md {
				display:block;
				img {
					width: 76px;
					height: 22px;
				}
			}
		}		
		//--- Service ---
		.flex-service {
			p, nav {
				display: flex;
			}
			a:hover {
				color:$white;
			}
			@include md {
				margin-top: 1.5em;
				order:2;
			}

			p, nav {
				display: flex;
			}
			a:hover {
				color:$white;
			}
		}
		
		//--- Address ---
		.flex-address {
			justify-content: center;
         padding-top: 3px;

			@include md {
				margin-top: 1.5em;
            padding-top: 0px;
			}
		}

		//--- Contact ---
		.flex-contact {
         padding-top: 3px;
         @include md {
            margin-top: 1.5em;
            padding-top: 0px;
         }

         p {
            text-align: left;
         }
         a:hover {
            color:$light-blue;
         }
		}

	}
}
