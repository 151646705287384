/*------------------------------------*\
    $VARIABLES
\*------------------------------------*/

//Colors
$black: #000000;
$white: #ffffff;
$red: #e90050;
$yellow: #ffb900;
$green: #00a7a7;
$dark-green:#006d6d;
$light-green:#00a628;
$light-blue:#49bbd5;
$dark-blue: #151543;
$title-grey:#75757a;
$grey:#5b5b5b;
$light-grey: #e3e3e4;
$bit-light-grey: #efefef;

//Typography
$font: 'AkkoPro-Regular', sans-serif;
$font-secondary: 'AkkoPro-Bold', sans-serif;

//Layout
$font-size-base: 20px;
$font-weight-normal: normal;
$font-weight-bold: bold;
$max-width: 47rem;
$max-width-wide: 49.5rem;

$column-gap: 1em;
$line-height-small: 100%;
$line-height: 150%;
$line-height-big: 175%;

// Typo
$font-size-micro: 0.5em; //10px
$font-size-mini: 0.6em; //12px
$font-size-mini-2: 0.7em; //14px
$font-size-small: 0.75em; //15px
$font-size-small-2: 0.85em; //17px
$font-size-med: 1em; //20px
$font-size-med-2: 1.25em; //25px
$font-size-large: 1.4em; //28px
$font-size-large-2: 1.6em; //32px
$font-size-extra-large: 1.75em; //35px
$font-size-extra-large-2: 2em; //40px
$font-size-extra-large-2-1: 2.6em; //52px
$font-size-extra-large-3: 2.8em; //56px

//Breakpoints
$bp-lg: 62.5rem;
$bp-md: 52rem;
$bp-sm: 34rem;
$bp-xs: 20rem;

//vertical space
$vertical-space-bottom: 3rem;
$vertical-space-bottom-big: 6rem;
