.contact {
	p {
		line-height: $line-height;
		font-size: $font-size-small-2;
		color: $white;

		&a {
			text-transform: uppercase;
		}
	}
}