.employee-box {
	display: flex;
	flex: 1 1 100%;
	padding: 1em 1.9em 1.5em 2em;
	background-color: $light-grey;
	@include md {
		flex: 1 0 auto;
		margin: 0;
	}
	&.touch-hover.special-hover {
		h3 {
			color: $white;
		}
		.employee-box-content {
			img {
				visibility: hidden;
			}
		}
		.employee-box-content-text, .link-to-page {
			position: absolute;
			display: block;
		}

		&.box-red {
			background-color: $red;
		}
		&.box-yellow {
			background-color: $yellow;
		}
		&.box-green {
			background-color: $green;
		}
		&.box-blue {
			background-color: $light-blue;
		}
		&.box-light-green {
			background-color: $light-green;
		}
		
		.link-to-page { background-image: url("../../assets/images/icons/arrow-link.svg");}
	}
	.employee-box-center {
		h3 {
			margin-bottom: 0.75rem;
			font-size: $font-size-large-2;
			@include md {
				font-size: $font-size-large;
				margin-top: 0.5rem;
				margin-bottom: 0;
			}
		}
		.employee-box-content-text, .link-to-page {
			display: none;
			@include md {
				font-size: $font-size-small-2;
			}
		}
	}
}

.employee-lists {
	.employee-box {
		padding-bottom: 0.8em;
		@include md {
			min-height: 330px;
		}
	}
}

