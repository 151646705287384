.picture-element {
	width:100%;
	min-height: 600px;
	float:left;
	
	@include md {
		min-height: auto;
	}
	.picture-element-content {
		display: flex;
		flex-flow: column nowrap;
		padding-top: 16em;
		padding-bottom: 1.5em;
		h1, .picture-element-text {
			color: $white;
		}
		.picture-element-text {
			margin-bottom: 1rem;
		}
		.link-to-page {
			width: auto;
		}
		@include md {
			padding-top: 11em;
			padding-left: 0.8em;
			h1 {
				margin-bottom:2rem;
			}
			.picture-element-text {
				display:none;
			}
			.link-to-page {
				font-size: $font-size-mini;
			}
		}
	}
}