.main-nav {
	display:none; 
	width:100%;
	min-height:500px;
	position:relative;
	z-index:100;
	
	@include md {
		display:none!important;
	}
	
	/*Top level */
	.main-nav-top {
		display:none;
		padding-top:2em;
		padding-bottom:2em;
		background-color:$dark-blue;

		a {
			font-size:$font-size-extra-large-2;
			background: url("../../assets/images/icons/arrow-link.svg") no-repeat right center/20px 20px;
			width:290px;
		}
		
		a:hover {
			color: $light-blue;
			background: url("../../assets/images/icons/arrow-link-light-blue.svg") no-repeat right center/20px 20px;
		}
	}
	

	/* first level */
	.main-nav-first {
		min-height:500px;
		background-color:$light-blue;
		padding-top:5em;

		a {
			font-size: $font-size-extra-large-3;
			background: url("../../assets/images/icons/arrow-link.svg") no-repeat right center/25px 25px;
			width:390px;
		}
		
		 a:hover { 
			color: $black;
			background: url("../../assets/images/icons/arrow-link-dark.svg") no-repeat right center/25px 25px;
		} 
	}




	.main-nav-second {
		display:none;
		position: absolute;
		width:50%;
		right:0;
		top:0;
		bottom:0;
		padding:5.5em 1rem 1rem 2rem;
		min-height: 100%;
		background-color: $yellow;
		
		a {
			width:auto;
			background:none;
			font-size: $font-size-large;
		}
	
		 a:hover { 
			background:none;
		  color: $black;
		}
		
	}
}

.navigation-show { 
	display:block!important; 	
}


///////////////////////////////////////////////////////// Modificator /////////////////////////////////////////////////////////

html .navigation-body-modifcator {
  @media only screen and ( min-width: $bp-md ){
	      overflow-y: scroll;
			background-image:none;
			background-color: $dark-blue;

			.main-footer{
				position:fixed;
				bottom: 0;
			}

			.main-content-div {
				height: 0;
				visibility: hidden;
				> div {
					margin:0;
					padding:0;
					height: 0;
					min-height: auto;
					overflow: hidden;
				}
				.main-footer {
					visibility: visible;
				}
			}
		}
}


