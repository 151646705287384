.top-navigation {
	.nav-list {
		padding: 0;
		li {
			list-style: none;
			a {
				color: $light-blue;
				text-align: center;
				font-size: $font-size-small-2;
				text-transform: uppercase;
			}
		}
	}
}