.main-nav-mobile {
	display:none;	
	a {
		padding:0.3em 1.25em 0.3em 0.8rem;
		width:95%;
	}
	
	
	.top {	
		background-color:$dark-blue;		
		
		a {
			padding-top:0.5em;
			padding-bottom:0.5em;
			font-size:$font-size-extra-large-2;
			background: url("../../assets/images/icons/arrow-link.svg") no-repeat right center/20px 20px;
		}
		
		a:hover {
			color: $light-blue;
			background: url("../../assets/images/icons/arrow-link-light-blue.svg") no-repeat right center/20px 20px;
		}
	}
	

	.second {
		display:none;
		background-color:$light-blue;
		position:relative;
		
		a {
			font-size:$font-size-large;
			background: url("../../assets/images/icons/arrow-link.svg") no-repeat right center/20px 20px;
		}

		.firstACT, a:hover { 
			color: $black;
			background: url("../../assets/images/icons/arrow-link-dark.svg") no-repeat right center/20px 20px;
		} 
	}
	
	
	.third {
		display:none;
		position:relative;
		width:100%;
		right:0;
		top:0;
		bottom:0;
		min-height: 100%;
		background-color: $yellow;
		padding-top:0.2em;
		padding-bottom:0.2em;
		
		a {
			font-size:$font-size-med;
		}
		.secondACT, a:hover { 
		  color: $black;
		}
	}
		
}








///////////////////////////////////////////////////////// Modificator /////////////////////////////////////////////////////////


html .navigation-body-modifcator-mobile {

			
			
	@media only screen and ( min-width: $bp-md ){
		.main-nav-mobile {
			display:none!important;
		}
	}
	
	 @include md {
		background-image:none;
		background-color: $dark-blue;
	
		.header-icons .mobile {
			background: url("../../assets/images/icons/nav-close.svg") no-repeat center right;
		}

		.main-content-div {
				height: 0;
				visibility: hidden;
				> div {
					height: 0;
					margin:0;
					padding:0;
					min-height: auto;
					overflow: hidden;
				}
				.main-footer {
					visibility: visible;
				}
		}

		.main-footer {
			position:relative;
			bottom: 0;
		}
	}
}

