.service-navigation {
	.nav-list {
		padding: 0;
		li {
			float: left;
			list-style: none;
			padding-left: 0.5em;
			a {
				text-align: center;
				font-size: $font-size-small;
				line-height: $line-height-small;
				color: $light-blue;
			}
			&:first-child {
				padding: 0 0.5em 0 0;
				border-right: 2px solid $light-blue;
			}
		}
	}
}