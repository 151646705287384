.text-modul {
	p {
		margin-bottom: 1em;
		font-size: $font-size-med;
		line-height: $line-height;
		color: $white;
		@include md {
			font-size: $font-size-mini;
		}
	}
	.link-to-page {
		@include md {
			font-size: $font-size-mini;
		}
	}
}