@import "scss/atoms/title-text";
@import "scss/atoms/employee-box-content";
@import "scss/atoms/download-link";
@import "scss/atoms/picture-tab-element";
@import "scss/atoms/image-element";

@import "scss/molecules/publication-element";
@import "scss/molecules/employee-box";

@import "scss/molecules/picture-element-speedtest";
@import "scss/molecules/picture-element-contact";

@import "scss/organisms/employee-lists";


//Navigations
@import "scss/molecules/main-navigation-desktop";
@import "scss/molecules/main-navigation-mobile";
@import "scss/atoms/service-navigation";