.main-header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 101;
	background-color: $dark-blue;
	.flex-container {
		align-items: center;
		min-height: 45px;
		position: relative;
		@include md {
			position: unset;
		}
		.flex-content {
			flex: 1 0 auto;
			&.navigation-content {
				@include md {
					display: none;
				}
				.top-navigation {
					min-width: 500px;
					.nav-list {
						display: flex;
						flex-flow: row nowrap;
						justify-content: space-around;
						li {
							a {
								line-height: $line-height;
							}
							a:hover {
								color:$white;
							}
						}
						.firstACT {
							color:$white;
						}
					}
				}
			}
			.logo {
				img {
					width: 136px;
					height: 38px;
					margin: 0.6em 0;
					&.layout-normal {
						width: 76px;
						height: 22px;
					}
				}
			}
			.header-icons {
				display: flex;
				flex-flow: row nowrap;
				justify-content: flex-end;
				align-items: center;
				.header-icons-element {
					&.mobile {
						display: none;
					}
					@include md {
						&.mobile {
							display: block;
						}
					}
				}
			}
		}	
	}
	&.layout-normal {
		z-index: 100;
	}
}
