@import "scss/generic/variables";
@import "scss/generic/mixins";
@import "scss/generic/reset";
@import "scss/generic/layout";
@import "scss/generic/kesearch";

@import "scss/atoms/headings";
@import "scss/atoms/image";
@import "scss/atoms/logo";


// personal main.scss
@import "main-boko.scss";
@import "main-jugr.scss";
@import "main-mike.scss";
@import "main-roman.scss";

/*******************************/
/* layout default page         */
/*******************************/
.mainDiv { 
	width: 100%; 
	max-width: 1000px;
}

.overlay { display:none; position:absolute; top:0; left:0; z-index:3000; opacity:0.1; min-height:100%; width:100%; background: #FFFFFF url("../../assets/images/raster/bit-raster.svg") repeat top left/8px 4px;}
.overlay-shadow {	display:none; position:fixed; width:100%;	height:100%; background-color:#000000; z-index: 2000; opacity:0.5 }
html .overlay-modificator { overflow:hidden; position:fixed; }

.mainSection {
	width: 80%;
	float: left;
}

/* Navigations */
nav a {
	display: block;
	text-decoration: none;
} 

/* all Titles */
.ceHeader {}

/* content elements */
.ceTextImage, .ceTextImageText {
	width: 100%;
	img {
		height:auto;
	}
}