.tab-element-head {
	float: left;
	width: 100%;
	padding-top: 20px;
	padding-bottom: 17px;
	cursor:pointer;
	.tab-element-title {
		float: left;
		width: 80%;
		color: $title-grey;
		font-size: $font-size-large-2;
		font-family: $font-secondary;
	}
	.tab-element-title:hover {
		color: $dark-blue;
	}
	.tab-element-active-title {
		color: $dark-blue;
	}
	.tab-icon {
		float:right;
		padding:1em;
		width:20px;
		height:20px;
		background: url("../../assets/images/icons/akkordeon-plus.svg") no-repeat center center/26px 26px;
		&.close {
			background: url("../../assets/images/icons/akkordeon-minus.svg") no-repeat center center/26px 26px;
		}
	}
}

