.info-modul-raster {
	padding-top: 4.5em;
	background: url("../../assets/images/raster/bit-raster-dark.svg") repeat top left/8px 4px;

	@include md {
		padding-top: 2.5em;
	}
	.site-center {
		flex-flow: column nowrap;
		justify-content: space-between;
		.info-modul-content-gap {
			h1 {
				float: left;
				margin-bottom: 0.2em;
			}
			.info-modul-content-row {
				display: flex;
				flex: 1 1 100%;
				justify-content: center;
				margin-bottom: 54px;
				&:last-child {
					margin-bottom: 0;
				}
				@include md {
					margin-bottom: 36px;
				}
				@include sm {
					margin-bottom: 18px;
				}
				.blue-raster-element {
					width: 238px;
					height: 106px;
					margin-right: 82px;
					@include lg {
						width: 190px;
						height: 84px;
						margin-right: 62px;
					}
					@include md {
						width: 120px;
						height: 62px;
						margin-right: 26px;
					}
					@include sm {
						width: 70px;
						height: 36px;
						margin-right: 26px;
					}
					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
		.start-element > * {
			display: none;
		}
		.start-element > span {
			float: left;
		}
	}
}