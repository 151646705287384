@import "scss/atoms/box-content";
@import "scss/atoms/link-to-page";
@import "scss/atoms/navigation";
@import "scss/atoms/header-icons";
@import "scss/atoms/address";
@import "scss/atoms/contact";
@import "scss/atoms/speedtest";
@import "scss/atoms/blue-raster-element";

@import "scss/molecules/box";
@import "scss/molecules/picture-element";
@import "scss/molecules/footer-service";
@import "scss/molecules/info-modul-raster";
@import "scss/molecules/info-modul-element";
@import "scss/molecules/text-modul";

@import "scss/organisms/box-lists";
@import "scss/organisms/header";
@import "scss/organisms/footer";
@import "scss/organisms/info-modul";

@import "scss/pages/landingpage";