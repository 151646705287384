.picture-element-speedtest {
	min-height:0;
	background: url("../../assets/images/raster/bit-raster-lightgreen.svg") repeat top left/8px 4px, url("../../assets/images/sample/speedtest.png") no-repeat top center/cover;
	
	@include md {
		min-height: auto;
	}
	.picture-element-content {
		display: flex;
		flex-flow: column nowrap;
		padding-top: 16em;
		padding-bottom: 1.5em;
		h1, .picture-element-text {
			color: $white;
		}
		.picture-element-text {
			margin-bottom: 1rem;
		}
		.link-to-page {
			width: auto;
		}
		@include md {
			padding-top: 11em;
			padding-left: 0.8em;
			h1 {
				font-size: $font-size-small-2;
			}
			.picture-element-text {
				font-size: $font-size-small;
			}
			.link-to-page {
				font-size: $font-size-mini;
			}
		}
	}
}