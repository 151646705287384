.publication-filter {
	.dropdowns {
		display:flex;
		flex-flow:row nowrap;
		justify-content:space-between;
		margin-top:2em;
		margin-bottom:1em;
      margin-left: -2px;
		span {
			display: inline-block; 
			white-space: nowrap;
			overflow: hidden;
			width: 90%;
			text-overflow: ellipsis;
		}
		@include md {
			flex-flow: row wrap;
		}
      @include sm {
         margin-left: 0;
      }
		.nice-select { 
			flex: 0 1 32%;
			max-width: 30.8%; // for ie 11
			@include md {
				flex: 1 1 100%;
				max-width: 100%; // for ie 11
			}
			.option {
				white-space: nowrap;
				overflow: hidden;
				width: 100%;
				text-overflow: ellipsis;
			}
		}
	}
	.publication-filter-search {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-end;
		flex: 1;
      margin-left: -2px;
      @include sm {
         margin-left: 0;
      }
		.filter-input {
			width: 100%;
			margin-bottom: 1em;
		}
		.filter-search, .filter-reset {
			height: 36px;
			width: 36px;
		}
		.filter-search {
			background: $light-blue url('../../assets/images/icons/header-icon-search.svg') no-repeat center center/60% 60%;
			border: none;
		}
		.filter-reset {
			background: $dark-blue url('../../assets/images/icons/header-icon-close.svg') no-repeat center center/50% 50%;
			border: none;
		}
	}
	.email-field {
		p {
			color:$grey;
			margin-bottom: 1em;
		}
		.email-flex {
			display:flex;
			margin-bottom:1em;
			.email-input {
				overflow: hidden;
				flex:1;
			}
			.email-search {
				flex:0 0 36;
				width: 36px;
				height:36px;
				border:none;
				background: $light-blue url('../../assets/images/icons/Akkordeon_plus_color_change.svg') no-repeat center center/60% 60%;
			}
		}
		.error-message {
			color:$light-blue;
			margin-bottom:2rem;
		}
	}
}