.employee-footer {
	clear:both;
	float: left;
   position: relative;
   width: 100%;
	min-height: 365px;
   font-size: $font-size-small-2;
	@include md {
		min-height: 300px;
	}
	@include sm {
		min-height: 366px;
	}
   .employee-footer-img {
      float: left;
      position: absolute;
      width: 328px;
      bottom: 0;
      z-index: 3;
		@include md {
			width: 270px;
		}
      @include sm {
        width: 270px;
      }
      img {
         float: left;
      }
   }
   .employee-footer-title {
      float: left;
      width: 45%;
      min-height: 152px;
      padding: 1.2em 0;
      margin-left: 55%;
      font-size: $font-size-large-2;
      font-family: $font-secondary;
      @include md {
			display:none;
         min-height: 0;
      }
   }
   .employee-footer-raster {
      float: left;
      position: absolute;
      width: 100%;
      bottom: 3.7em;
      height: 150px;
      z-index: 0;
		@include md {
			bottom: 5.5em;
		}
   }
   .employee-footer-contact {
      float: left;
      position: relative;
      padding: 1.5em 0;
      margin-left: 55%;
      line-height: 150%;
      z-index: 99;
		font-size: $font-size-base;
		@include md {
			padding-top: 100px;
			margin-left: 45%;
		}
      @include sm {
         width: 100%;
			margin-left: 0;
			padding-top: 15px;
      }
      .contact-name {
         font-weight: bold;
      }
		p {
			@include md {
				display:none;
			}
		}
   }
   .employee-footer-link {
      float: left;
      width: 45%;
      margin-left: 55%;
		padding: 0.75em 0;
		font-size:$font-size-large;
		@include md {
			display:none;
		}
      .link-to-page {
         width: 85px;
      }
   }
}
   
   
   
.footer-bg-red {
   background-color: $red;
   .employee-footer-raster {
      background: url("../../assets/images/raster/bit-raster-darkred.svg") repeat top left/8px 4px;
   }
}
.footer-bg-blue {
   background-color: $light-blue;
   .employee-footer-raster {
      background: url("../../assets/images/raster/bit-raster-blue.svg") repeat top left/8px 4px;
   }
}
.footer-bg-yellow {
   background-color: $yellow;
   .employee-footer-raster {
      background: url("../../assets/images/raster/bit-raster-darkyellow.svg") repeat top left/8px 4px;
   }
}
.footer-bg-green {
   background-color: $green;
   .employee-footer-raster {
      background: url("../../assets/images/raster/bit-raster-darkgreen.svg") repeat top left/8px 4px;
   }
}
.footer-bg-light-green {
   background-color: $light-green;
   .employee-footer-raster {
      background: url("../../assets/images/raster/bit-raster-lightgreen.svg") repeat top left/8px 4px;
   }
}
