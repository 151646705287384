.tab-element {
	float: left;
	width: 100%;
	background: url("../../assets/images/raster/bit-raster-grey.svg") repeat-x bottom left/8px 4px;
	background-color: $bit-light-grey;

	.site-center {
		cursor: default;
	}
	.tab-element-head {
		cursor: pointer;
	}
}

.tab-content {
	float: left;
	width: 100%;
}

