.link-to-page {
	float: left;
	width: 100%;
	padding-right: 1.5em;
	line-height: $line-height;
	font-size: $font-size-small;
	background: url("../../assets/images/icons/arrow-link.svg") no-repeat right center/12px 12px;
}

.link-to-page-white {
	color: $white;
}
.link-to-page-white:hover {
	color: $dark-blue;
	background: url("../../assets/images/icons/arrow-link-blue.svg") no-repeat right center/12px 12px;

}
.link-to-page-blue {
	color: $light-blue;
}
.link-to-page-blue:hover {
	color: $white;
}
.link-to-page-grey {
	color: $grey;
}
.link-to-page-news {
	float: left;
	width: 296px;
	padding: 0 2.0em 0 0.6em;
	line-height: $line-height;
	font-size: $font-size-mini-2;
	color: $white;	
	background-color: $light-blue;
	border-right: 1px solid $light-blue;
	border-bottom: 2px solid $light-blue;
	border-left: 1px solid $light-blue;
	border-radius: 6px;
	background-position: right 8px top 6px;
	z-index:3;
	&.fixed {
		top: 62px;
		margin-left: 302px;
		@include md {
			margin: 0 1em;
		}
		@include xs {
			margin: 0 0.4em;
		}
		&.fixed:hover {
			color:$dark-blue;
		}	
	}
	&.layout-normal {
		top: 46px!important;
	}
	&.news-removed {
		display: none;
	}
	&.fixed:hover {
		background-image: url("../../assets/images/icons/arrow-link-blue.svg"); 
	}
}