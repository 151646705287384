.box {
	display: flex;
	flex: 1 1 100%;
	padding: 1em 1.7em 2em 1.7em;
	.box-center {
		display: flex;
		flex: 1 1 100%;
		flex-flow: column wrap;
		h2 {
			margin-bottom: 0.75rem;
			font-size: $font-size-large-2;
			@include md {
				font-size: $font-size-large;
				margin-bottom:0;
			}
		}
		.title-subline {
			display:none;
		}
		.box-content-text, .link-to-page {
			display: none;
		}
		.link-to-page {
			text-transform: uppercase;
		}
	}
	&.touch-hover.special-hover {
		.box-content {
			background-image: none;
			height:0;
			img {
				display: none;
			}
		}
		.box-content-text, .link-to-page {
			display: block;
		}
		.title-subline {
			display:block;
		}
		&.box-blue {
			background-color: $light-blue;
		}
		&.box-red {
			background-color: $red;
		}
		&.box-yellow {
			background-color: $yellow;
		}
		&.box-green {
			background-color: $green;
		}
	}


	@include md {
		flex: 1 0 auto;
		&.box-blue {
			background-color: $light-blue;
		}
		&.box-red {
			background-color: $red;
		}
		&.box-yellow {
			background-color: $yellow;
		}
		&.box-green {
			background-color: $green;
		}
		h2 { 
			background: rgba(0, 0, 0, 0) url("../../assets/images/icons/arrow-link.svg") no-repeat scroll right center / 15px 15px
		}
	}
}