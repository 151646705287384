.download-link {
	width:auto;
	a {
		background: url("../../assets/images/icons/arrow-link-grey.svg") no-repeat right /12px 12px;
		padding-right:2em;
		font-size:$font-size-mini-2;
		color:$grey;
	}
}
.download-link-page {
	margin-top:1em;
}

.download-link {
   .hover-red {
      &:hover {
         background: url("../../assets/images/icons/arrow-link-red.svg") no-repeat right /12px 12px;
         color:$red;
      }
   }
}
.download-link {
   .hover-yellow {
      &:hover {
         background: url("../../assets/images/icons/arrow-link-yellow.svg") no-repeat right /12px 12px;
         color:$yellow;
      }
   }
}
.download-link {
   .hover-green {
      &:hover {
         background: url("../../assets/images/icons/arrow-link-green.svg") no-repeat right /12px 12px;
         color:$green;
      }
   }
}
.download-link {
   .hover-blue {
      &:hover {
         background: url("../../assets/images/icons/arrow-link-blue.svg") no-repeat right /12px 12px;
         color:$dark-blue;
      }
   }
}