.section-element-title {
   float: left;
	position:relative;
   width: 100%;
   min-height: 105px;
   padding-top: 28px;
	padding-bottom: 28px;
   text-align: center;
   font-size: 2.1em;

   @include md {
		min-height: 80px;
      padding-top: 20px;
		padding-bottom: 20px;
      font-size: $font-size-large-2;
   }
   @include sm {
		min-height: 70px;
      padding-top: 18px;
		padding-bottom: 18px;
      font-size: $font-size-large;
   }
	.section-element-title-text {
		padding-right:1.7em;
		padding-left:1.7em;
	}
	
	.site-center {	
		position: relative;
	}
	
	.close-overlay {
		display:none;
      position:absolute;
		left:0.3em;
		top:2px;
		padding:0.5em;
		background: url("../../assets/images/close-x.svg") no-repeat center center/25px 25px;
		@include md {
			left:0.6em;
		}
   }
}
