h1, h2, h3, h4 { 
	margin: 0 0 0.4em 0;
	font-family: $font;
	font-weight: $font-weight-normal;
	color: $white;
}
h1, h1 a {
	font-size: $font-size-extra-large-2-1;
	line-height: $line-height-small;
	@include md {
		font-size: $font-size-med-2;
	}
}
h2, h2 a {
	font-size: $font-size-extra-large-2;
	line-height: $line-height;
	@include md {
		font-size: $font-size-large;
	}
}
h3, h3 a {
	font-size: $font-size-large-2;
	@include md {
		font-size: $font-size-large;
	}
}
h4, h4 a {
	font-size: $font-size-large;
}