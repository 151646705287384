.box-content-div {
	.box-content {
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		height: 260px;
	}
	.box-content-text {
		font-size: 1.1em;
		line-height: $line-height;
		color: $white;
		margin-top:0.4em;
	}
	.link-to-page {
		float: none;
		width: 60px;
		margin-top:3em!important;
		font-weight: bold;
		@include md {
			width: 70px;
		}
	}
	

	.box-image {
		display: block;
		height:100%;
		width:100%;

		&.security { background: url("../../assets/images/security.svg") no-repeat center center/158px 198px; }
		&.performance { background: url("../../assets/images/performance.svg") no-repeat center center/182px 190px; }
		&.software { background: url("../../assets/images/software.svg") no-repeat center center/166px 142px; }	
	}

}


.box-content-blue {
	background: url("../../assets/images/raster/bit-raster-blue.svg") repeat top left/8px 4px;;
	@include md {
		background: url("../../assets/images/raster/bit-raster-blue.svg") repeat top left/8px 4px;;
	}
}
.box-content-red {
	background:  url("../../assets/images/raster/bit-raster-red.svg") repeat top left/8px 4px;;
	@include md {
		background: url("../../assets/images/raster/bit-raster-darkred.svg") repeat top left/8px 4px;;
	}
}
.box-content-yellow {
	background: url("../../assets/images/raster/bit-raster-yellow.svg") repeat top left/8px 4px;
	@include md {
		background: url("../../assets/images/raster/bit-raster-darkyellow.svg") repeat top left/8px 4px;;
	}
}
.box-content-green {
	background:  url("../../assets/images/raster/bit-raster-green.svg") repeat top left/8px 4px;;
	@include md {
		background: url("../../assets/images/raster/bit-raster-darkgreen.svg") repeat top left/8px 4px;;
	}
}


