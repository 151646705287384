.box-lists {
	background: $dark-blue url("../../assets/images/raster/bit-raster-dark.svg") repeat top left/8px 4px;
	clear: both;
	.flex-container {
		flex-flow: row nowrap;
		@include md {
			flex-flow: column nowrap;
		}
		.box {
			@include md {
				padding: 0.2rem 0.9rem 0.8rem 0.8rem;
			}
			.box-center {
				@include md {
					font-size: $font-size-med-2;
				}
			}
		}
	}
}
