.main-div {
	.text-bg {
			background-color: $dark-blue;
			width:100%;
			float:left;
		}
		
	.site-center {
		.text-modul {
			max-width: 720px;
			padding: 1em 1em 1em 0;
			.link-to-page {
				width: auto;
			}
		}
	}
}