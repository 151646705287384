.section-element {
   float: left;
   margin-bottom: 2rem;
   width: 100%;
	z-index:100;
   .section-element-raster {
      width: 100%;
      height: 260px;
      @include md {
         height: 230px;
      }
   }
	&.no-bottom-space{
		margin-bottom:0!important;
	}
}


.section-element-small {
	position: fixed;
	top:62px;
	margin-bottom:0!important;
	
	.section-element-title {
		height:auto;
		min-height: 106px;
	}
	
	.section-element-title-text {
			@include md {
				padding-left: 2.7em;
				font-size: $font-size-small;
			}
		}
}



.section-red {
   background-color: $red;
   .section-element-raster {
      background: url("../../assets/images/raster/bit-raster-darkred.svg") repeat center center/8px 4px;
   }
   .section-element-title {
      background-color: $red;
   }
}
.section-yellow {
   background-color: $yellow;
   .section-element-raster {
      background: url("../../assets/images/raster/bit-raster-darkyellow.svg") repeat center center/8px 4px;
   }
   .section-element-title {
      background-color: $yellow;
   }
}
.section-blue {
   background-color: $light-blue;
   .section-element-raster {
      background: url("../../assets/images/raster/bit-raster-blue.svg") repeat center center/8px 4px;
   }
   .section-element-title {
      background-color: $light-blue;
   }
}
.section-green {
   background-color: $green;
   .section-element-raster {
      background: url("../../assets/images/raster/bit-raster-darkgreen.svg") repeat center center/8px 4px;
   }
   .section-element-title {
      background-color: $green;
   }
}
.section-lightgreen {
   background-color: $light-green;
   .section-element-raster {
      background: url("../../assets/images/raster/bit-raster-darkgreen.svg") repeat center center/8px 4px;
   }
   .section-element-title {
      background-color: $light-green;
   }
}