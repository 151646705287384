.address {
	p {
		display:block;
		line-height: $line-height;
		font-size: $font-size-small-2;
		text-transform: uppercase;
		color: $light-blue;
	}
	
	&:hover p {
		color: $white;
	} 
}
