.header-top-search {
	display: none;
	position: absolute;
	top: 13px;
	right: 20px;
	z-index: 8001;
	 
	@include md {
		top: 12px;
		right: 17px;
	}
	.header-search-box {
		display: flex;
		justify-content: flex-end;
		flex: 1;
		form {
			display: flex;
			justify-content: flex-end;
			flex: 1;
			.search-text{
				float: left;
				width: 100%;
				height: 39px;
				color: $light-blue;
				overflow: hidden;
			}
			.search-button {
				float: left;
				height: 39px;
				width: 39px;
				background: $light-blue url('../../assets/images/icons/header-icon-search.svg') no-repeat center center/60% 60%;
				border: none;	
			}
		}
		.close {
			width: 39px;
			height: 39px;
			background: $black url('../../assets/images/icons/header-icon-close.svg') no-repeat center center/50% 50%;
		}
	}
	&.layout-normal {
		top: 8px !important;
		.search-text, .search-button, .close {
			height: 30px !important;
		}
	}
}