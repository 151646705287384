.employee {
	.employee-box-content-text {
		font-size: $font-size-med;
		line-height: $line-height;
		color: $white;
		margin-top:1em;
		display:none;
	}
	h3 {
		color:$grey;
	}
	employee-link-to-page {
		.link-to-page {
			float: none;
			width: 60px;
			font-weight: bold;
			@include md {
				width: 70px;
			}
		}
	}
	.employee-box-content {
		img {
			display: flex;
			align-self: left;
			width: 100%;
			height: auto;
			margin-top:2em;
		}
	}
}