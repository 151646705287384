html, body {
	height: 100%; /* for full height screen */
	margin: 0;
	padding: 0;
	font-family: $font; 
	font-size: $font-size-base;
	color: $white;
	letter-spacing: 0.8px;
	background: #FFFFFF url("../../assets/images/raster/bit-raster.svg") repeat center center/8px 4px;
}
.main-div {
	position: relative;
	height: 100%;
	padding-top: 62px;
	&.layout-normal {
		padding-top: 45px;
	}
	.main-content-div {
		height: 100%;
		@include md {
			height: auto;
		}
	}
}

/* layout center */
.site-center {
	width: 100%;
	max-width: $max-width;
	margin: 0 auto;
}

.site-center-wide {
	margin: 0 auto;
	width: 100%;
	max-width: $max-width-wide;
}


.site-gap {
	padding-left: 1em;
	padding-right: 1em;
	@include md {
		padding-left: 0.85em;
		padding-right: 0.85em;
	}
}

/* flexbox container */
.flex-container {
	display: flex;
	flex: 1 1 100%;
}

/* fixed elements */
.fixed {
	position: fixed;
	left: 50%;
	transform: translateX(-50%);
	@media only screen and (max-width: 960px) {
		left: auto;
		right: 0;
		transform: translateX(0%);
		margin-left: 0;
		margin-right: 1.5em;
	}
}

/* overlay elements */
.overlay-content {
	.section-element {
		margin-bottom: 0;
		.section-element-title {
			height: 106px;
			@include md {
				height: 80px;
			}
			@include sm {
				height: 100px;
			}
			.section-element-title-text {
				@include md {
					padding-left: 2.7em;
					font-size: $font-size-small;
				}
			}
		}
	}
}








hr { 
	height: 1px; 
	background-color: $black; 
}
ul, ol { 
	padding-left: 24px;
}
a { 
	color: $white; 
	text-decoration: none;
}
a:hover {
	color: $white;
}

//vertical spaces
.vertical-space-bottom {
	margin-bottom: $vertical-space-bottom;
	.employee-footer {	
		margin-bottom: $vertical-space-bottom;
	}
}
.vertical-space-bottom-big {
	margin-bottom: $vertical-space-bottom-big;
}

.googlemaps {
	float:left;
	@include md {
		height:500px;
	}
}

.rte-table {
	width: 100% !important;
	max-width: $max-width;
	td, th {
		padding: 0.5em;
		@include sm {
			font-size: 0.65em;
		}
	}
}