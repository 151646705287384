.header-icons {
	.header-icons-element {
		margin-right: 0.8em;
		&:last-child {
			margin-right: 0;
		}
		img {
			display: block;
		}
		&.search {
			width: 20px;
			height:20px;
			background-image: url("../../assets/images/icons/header-icon-search.svg");
		}
		&.search:hover {
			background-image: url("../../assets/images/icons/header-icon-search-blue.svg");
		}
		&.download {
			width: 18px;
			height: 18px;
			background-image: url("../../assets/images/icons/header-icon-download.svg");
			background-repeat: no-repeat;
		}
		&.download:hover {
			background-image: url("../../assets/images/icons/header-icon-download-blue.svg");
		}

		&.secure {
			font-size: $font-size-small;
			color: $white;
			@include md {
				display: none;
			}
			&:hover {
				color:$light-blue;
			}
		}
		&.language {
			font-size: $font-size-small;
			margin-right: 0;
			@include md {
				margin-right: 0.8em
			}

			color:$light-blue;
			
			&:hover, &.active{
				color: $white;
			}
		}

		
		&.mobile {
			height:14px;
			width: 30px;
			background-image: url("../../assets/images/icons/nav-open.svg");
		}
	}
   .nice-select {
      z-index: 8000;
      width: 33%;
      max-width: 55px;
      background-color: transparent;
      margin-bottom: 0;
      padding-left: 0;
      font-size:15px;
      
      @include sm {
         width: auto;
         min-width: 52px;
         &:after {
            border: none;
         }
      }
      &:after {
         border-bottom: 2px solid #FFFFFF;
         border-right: 2px solid #FFFFFF;
         height: 11px;
         margin-top: none;
         right: 18px;
         top: 50%;
         padding-right: 6px;
      }
      .current {
         color: #FFFFFF;
      }
   }
}