.employee-lists {
	@include md {
		margin-bottom:1em;
	}
	.flex-container {
		flex-flow: row nowrap;
		@include md {
			flex-flow: column nowrap;
		}
		.employee-box {
			@include md {
				padding: 0.8rem 0.9rem 0.8rem 0.8rem;
			}
			.employee-box-center {
				@include md {
					font-size: $font-size-med-2;
				}
			}
		}
	}
}

.title-red h2 {
	color:$red;
}
.title-yellow h2 {
	color:$yellow;
}
.title-green h2 {
	color:$green;
}

.employee-lists-title {
	margin-top:2em;
	h2 {
		 font-family: $font-secondary;
	}
	@include md {
		margin-top:4em;
	}
}