.info-modul-element {
	position: relative;
	height: 44px;
	line-height: $line-height;
	.icon {
		width: 16px;
		height: 16px;
		margin-right: 0.4em;
		background: url("../../assets/images/icons/arrow-down.svg") no-repeat left center/12px 12px;
		@include md {
			margin-right: 0.5em;
		}
	}
	p {
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: $line-height;
		font-size: $font-size-mini-2;
		&.title {
			text-transform: uppercase;
			color: $light-blue;
		}
		&.text {
			@include md {
				display: none;
			}
		}
		@include md {
			font-size: $font-size-micro;
		}
	}
	p:hover {
		color:$white;
	}
	.info-modul-icon {
		display: flex;
		align-items: center;
		a:hover {
			color:$dark-blue;
		}
	}
	.info-modul-speedtest {
		flex: 1 1 0%;
		justify-content: flex-end;
		@include md {
			.speedtest {
				margin-left: 0.5em;
			}
		}
	}
}