.picture-tab-element {
	position:relative;
	margin-bottom:1.8em;
	img {
		margin-top:1.5em;
		height:auto;
		width:40%;
		@include md {
			width:55%;
		}
	}
	.picture-tab-element-text {
		position:absolute;
		bottom:0;
		padding-left:50%;
		@include md {
			padding-left:0;
			padding-top:0.5em;
			position:inherit;
		}
		.link-mail {
			background: url("../../assets/images/icons/arrow-link-dark.svg") no-repeat right /12px 12px;
		}
		a {
			color:$dark-blue;
			font-size:$font-size-med;
			padding-right:1.5em;
         &:hover {
            color:$light-blue;
         }
		}

	}
}
