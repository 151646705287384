.info-modul {
	display: flex;
	flex-flow: column nowrap;
	background-color: $dark-blue;
	height: 100%;
	.info-modul-element {
		flex: 0 1 auto;
	}
	
	
	@include md {
		height:380px;		
	}
}