.section-element-graphic {
   width: 326px;
   margin-left: auto;
   margin-right: auto;
   padding-top: 96px;
   
   @include md {
      width: 300px;
      padding-top: 80px;
   }
   .section-element-graphic-box {
      float: right;
      width: 86px;
      height: 42px;
      margin-top: 18px;
      background: url("../../assets/images/raster/bit-raster-white.svg") repeat top left/8px 4px;
      
      &:first-child {
         margin-top: 0;
      }
      @include md {
         width: 78px;
         height: 38px;
      }
   }
   
   
}

.firstline {
   margin-left: 70%;
}
.secondline {
   margin-left: 34px;
   @include md {
      margin-left: 30px;
   }
   &:last-child {
      margin-left: 30%;
   }
}
.thirdline {
   margin-left: 34px;
   @include md {
      margin-left: 30px;
   }
   &:last-child {
      margin-left: 0;
   }
}