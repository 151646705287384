.tab-element-title-text {
   float: left;
   width: 100%;
   margin-top: 30px;
	margin-bottom: 30px;
   color: $grey;
   font-size: $font-size-med;

	&.tab-element-title-text-blue {
		a {
			color: $light-blue;
		}
	}
	&.tab-element-title-text-green {
		a {
			color: $green;
		}
	}
	&.tab-element-title-text-light-green {
		a {
			color: $light-green;
		}
	}
	&.tab-element-title-text-red {
		a {
			color: $red;
		}
	}
	&.tab-element-title-text-yellow {
		a {
			color: $yellow;
		}
	}
	
	li ul {
		list-style-image:url(../../../Public/assets/images/icons/akkordeon-bulletpoints.svg);
	}

   &:last-child {
      padding-bottom: 40px;
   }

   h5 {
      color: $dark-blue;
      font-size: $font-size-med;

   }
}