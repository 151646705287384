.box-elements {
	display:flex;
	flex-flow: row wrap;
	justify-content: space-between;
	margin-bottom:-2rem;
	@include md{ 
		margin-bottom:0; 
	}
}

.box-element{
	color:$grey;
	h3 {
		font-family: $font-secondary;
		color:$grey;
	}
	.text{
		font-size: $font-size-med;
	}

	position: relative;
	background-color: $light-grey;
	min-height: 215px;
	flex: 0 1 47%;
	max-width: 47%; // for ie 11
	margin-bottom: 2rem;
	padding: 1.3rem 1.3rem 3.3rem 1.3rem;
	
	order: 99;
	
	&.box-position-1 {
		order: 1;
	}
	&.box-position-2 {
		order: 2;
	}
	&.box-position-3 {
		order: 3;
	}
	&.box-position-4 {
		order: 4;
	}
	&.box-position-5 {
		order: 5;
	}
	&.box-position-6 {
		order: 6;
	}
	&.box-position-7 {
		order: 7;
	}
	&.box-position-8 {
		order: 8;
	}
	&.box-position-9 {
		order: 9;
	}
	&.box-position-10 {
		order: 10;
	}

	@include md{
		flex: 1 1 100%;
		max-width: none; // for ie 11
	}

	.link-to-page  {
		background-image: url("../../assets/images/icons/arrow-link-grey.svg");
		display:block;
		position:absolute;
		bottom:0em;
		width:auto;
		margin-bottom: 1em;
	}

	&:hover {
		&:not(.touch-hover) {
			color: $white;
			h3 { 
				color:$white;
			}
			.link-to-page { background-image: url("../../assets/images/icons/arrow-link.svg");}

			&.box-red {
				background-color: $red;
			}
			&.box-yellow {
				background-color: $yellow;
			}
			&.box-green {
				background-color: $green;
			}
			&.box-blue {
				background-color: $light-blue;
			}
			&.box-light-green {
				background-color: $light-green;
			}
		}
	}
	&.box-active {
		order: -1;
	}
}

