.speedtest {
	letter-spacing:0;
	width: 126px;
	padding-left: 1.7em;
	font-size: $font-size-mini-2;
	line-height: $line-height-big;
	background: url("../../assets/images/icons/speedtest.svg") no-repeat left center;
   &:hover {
      color: $dark-blue;
   }
	&.fixed {
		top: 45px;
		margin-left: 387px;
		z-index: 10;
		@media only screen and (max-width: 960px) { 
			margin-right: 1em;
		}
	}
	&.fixed-normal {
		top: 60px !important;
		@media only screen and (max-width: 960px) { 
			margin-right: 0.85em !important;
		}
	}
}