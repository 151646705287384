.publication-elements {
	padding-top:1em;
	padding-bottom:1em;
	background: url("../../assets/images/raster/bit-raster-dark.svg") repeat-x bottom left/8px 4px;
	background-color:$light-grey;
	h2, h3 {
		color:$dark-blue;
	}
	p {
		color:$grey;
	}
	a {
		color:$grey;
	}
        a:hover {
           color:$light-blue; 
        }
	.download-link a:hover {
                background:  url("../../assets/images/icons/arrow-link-light-blue.svg") no-repeat right center/12px 12px;
	}
	.site-center .site-gap {
		padding-left:0;
		padding-right:0;
	}

	.link-to-page {
		color:$grey;
		background:  url("../../assets/images/icons/arrow-link-grey.svg") no-repeat right center/12px 12px;
		float:none;
		display:inline;
	}
}

